import $ from 'jquery'
import breakpoint from './breakpoint'

export class Header {

    constructor(node) {
        this.header = $(node)
        this.window = $(window)
    }

    init() {
        this.toggleFixed()
        this.bindings()
    }

    bindings() {
        this.window.on('scroll', this.toggleFixed.bind(this))
        this.window.on('resize', this.toggleFixed.bind(this))
    }

    toBeOrNotToBeFixed() {
        const headerStickyHeight = 92       
        const headerMid = this.header.height() - headerStickyHeight //36
        const windowScrollTop = window.pageYOffset // window.scrollY       
        return (windowScrollTop > headerMid) && breakpoint.min('xl')
    }

    toBeOrNotToBeFixedLogo() {
        let $logo = $('.svg--header-logo')
                
        let logoFullWidth = 190 
        let logoFullHeight = 120
        const scrollSize = 36 
        const windowScrollTop = window.pageYOffset

        let logoCalWidth = logoFullWidth
        let logoCalHeight = logoFullHeight        
        
        if (windowScrollTop > 0) {

            if (windowScrollTop < scrollSize) {           

                logoCalWidth -= (windowScrollTop)
                logoCalHeight -= (windowScrollTop)

            } else {           
                logoCalWidth = (logoFullWidth - scrollSize)
                logoCalHeight = (logoFullHeight - scrollSize)
            }

            $logo.css({
                'width': `${logoCalWidth}px`,
                'height': `${logoCalHeight}px`
            })

            return

        }

        $logo.removeAttr('style')
              
    }
    
    toggleFixed() {

        if (this.toBeOrNotToBeFixed()) {
            this.header.addClass('state-fixed')
        } else {
            this.header.removeClass('state-fixed')
        }

        if (breakpoint.min('xl')) {
            this.toBeOrNotToBeFixedLogo()
        }
        
    }
}

export default function init(selector) {
    const nodes = Array.from(document.querySelectorAll(selector))
    nodes.forEach(node => {
        const module = new Header(node)
        module.init()
    })
}
