// This parallax plugin is only recommended if you cannot get any other JS plugin to work.
// This plugins strong side is that does minimal modifications on the DOM, however, it performs badly on slower devices

import $ from 'jquery';

var getImageSize = require("hesehus-js-image-size");

var instanceCounter = 0;

function setup (el, opt) {
    opt = opt || {};

    var $this = $(el),
        existingInstance = $this.data("hparallax");

    // Destroy and stop
    if (opt === "destroy") {
        if (existingInstance) {
            existingInstance.destroy();
        }
        return;
    }

    // Remove any old instance
    if (existingInstance) {
        existingInstance.destroy();
    }

    var options = {
        backgroundPosition: "center center",
        getImageDimension: $.Deferred(),
        speedFactor: .25,
        allowBackgroundOffCanvas: false,
        eventIdentifier: "parallax" + instanceCounter++,
        $el: $this,
        $scrollTarget: $(window),

        // Some predefined names for different speed factors
        typesSpeedFactor: {
            FasterThanScroll: 1.25,
            SlowerThanScroll: -.25,
            Fixed: -1
        },

        getWidth: function () {
            return this.$el.width();
        },
        getHeight: function () {
            return this.$el.height();
        },
        updateDimensions: function () {
            this.offset = this.$el.offset();
            this.position = this.$el.position();
            this.width = this.getWidth(),
            this.height = this.getHeight();
            this.scrollerHeight = this.$scrollTarget.height(),

            this.update();
        },
        update: function () {
            var that = this;

            that.scrollerScrollTop = that.$scrollTarget.scrollTop();

            var notInViewport = (that.scrollerScrollTop + that.scrollerHeight < that.position.top) || that.scrollerScrollTop > that.position.top + that.height;

            // Make sure the item is visible before applying parallax behaviour
            if (notInViewport) {
                return;
            }

            // @ first update
            if (!that.bgSize) {
                that.getImageDimension.then(function (bgSize) {

                    that.bgSize = bgSize;

                    if (that.bgSize) {

                        // Get the displayed width (the background size should be 100% auto)
                        that.widthDiff = that.width / that.bgSize.width
                        that.bgSize.heightDisplayed = that.bgSize.height * that.widthDiff;

                        // Get the max y start position (100%)
                        that.ypos100 = (that.bgSize.heightDisplayed - that.height) * -1;

                        // Get ypos from background position (can be ex. "center center" or just the shorthand "center")
                        var backgroundPositionParts = that.backgroundPosition.split(" ");
                        that.xpos = backgroundPositionParts[0];
                        that.ypos = backgroundPositionParts[1] || that.xpos;

                        // Make position set with center/top/bottom
                        if (that.ypos === "center") {
                            that.ypos = "50%";
                        }
                        else if (that.ypos === "top") {
                            that.ypos = "0%";
                        }
                        else if (that.ypos === "bottom") {
                            that.ypos = "100%";
                        }

                        that.yposPct = parseFloat(that.ypos) / 100;
                        if (that.yposPct > 1) {
                            that.yposPct = 1;
                        }
                        else if (that.yposPct < 0) {
                            that.yposPct = 0;
                        }

                        that.yposStart = Math.round(that.ypos100 * that.yposPct);

                        handlePosition();
                    }
                });
            }
            // @ other updates
            else {
                handlePosition();
            }

            function handlePosition () {

                // The initaial calculation is not done. Lets wait
                if (!that.bgSize.heightDisplayed) {
                    return;
                }

                // The background image is not big enough to cover the elements height
                if (that.bgSize.height <= that.height) {
                    return;
                }

                var relativeScroll = that.scrollerScrollTop - that.position.top;
                var newYpos = Math.ceil(that.yposStart - (relativeScroll * that.speedFactor));

                if (!that.allowBackgroundOffCanvas) {
                    if (newYpos > 0) {
                        newYpos = 0;
                    }
                    else if (newYpos < that.ypos100) {
                        newYpos = that.ypos100;
                    }
                }

                that.$el.css({
                    backgroundPosition: that.xpos + " " + newYpos + "px"
                });
            }
        },
        destroy: function () {
            this.$scrollTarget.off("scroll." + this.eventIdentifier);
            this.$scrollTarget.off("resize." + this.eventIdentifier);
            this.$el.data("parallax", null);
        }
    };

    // Extend custom options
    if (typeof opt !== "string") {
        $.extend(true, options, opt);
    }

    var dataType = $this.data("parallaxbehaviour");
    if (!options.type && dataType) {
        options.type = dataType;
    }

    // Speed factor is not set, but yype is. Lets determine the speedFactor from that
    if (!opt.speedFactor && options.type) {

        // Stop
        if (options.type === "None") {
            return;
        }

        options.speedFactor = options.typesSpeedFactor[options.type] || options.speedFactor;
    }

    // Stop if the type is set to "None"
    if (options.speedFactor === 0) {
        return;
    }

    // Get the background image
    options.backgroundImage = options.backgroundImage || $this.css("background-image");
    if (!options.backgroundImage || options.backgroundImage.indexOf("url(") === -1) {
        return;
    }
    options.backgroundImage = cleanSrc(options.backgroundImage.substr(4, options.backgroundImage.length - 5));

    // Get the image dimensions
    getImageSize(options.backgroundImage, function (err, size) {
        options.getImageDimension.resolve(size);
    });

    // Bind on scroll and resize
    options.$scrollTarget.on("scroll." + options.eventIdentifier, function () {
        options.update();
    }).on("resize." + options.eventIdentifier, function () {
        options.updateDimensions();
        options.update();
    });

    // Init
    options.updateDimensions();
    options.update();

    // Set correct background size
    options.$el.css({ backgroundSize: "100% auto" });

    // Store options
    options.$el.data("hparallax", options);

    function cleanSrc (src) {
        if (src.indexOf("'") === 0 || src.indexOf('"') === 0) {
            src = src.substr(1, src.length - 2);
            cleanSrc(src);
        }

        return src;
    }
}

// Register a jQuery plugin
$.fn.hParallax = function (opt) {
    return this.each(function () {
        setup(this, opt);
    });
};

module.exports = setup;
