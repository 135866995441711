/*

	The main JS file.

*/

// Node modules
import 'babel-polyfill'
import 'jsnlog'
window.jQuery = window.$ = require('jquery')
require('bootstrap/js/src/dropdown')

// Modules and misc.
import './views/page/index'
import './typography'
import './modules/objectFit'
import header from './modules/header.js'
import {mobilemenuInit, sidemenuInit} from './modules/menu.js'
import toTop from './modules/toTop.js'
import dropdown from './modules/dropdown.js'
import search from './modules/search.js'

// Module init
header('[data-js="Header"]')
mobilemenuInit('[data-js="Mobilemenu"]')
sidemenuInit('[data-js="Sidemenu"]')
toTop('[data-js=toTop]', '.nav__item--toTop')
dropdown('[data-js=dropdown]')
search('[data-js=search]')
