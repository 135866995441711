import FontFaceObserver from 'fontfaceobserver'

const openSans = new FontFaceObserver('Open Sans', {weight: 400})
const openSansBold = new FontFaceObserver('Open Sans', {weight: 700})
const roboto = new FontFaceObserver('Roboto', {weight: 400})
const robotoBold = new FontFaceObserver('Roboto', {weight: 700})

Promise.all([
    openSans.load(),
    openSansBold.load(),
    roboto.load(),
    robotoBold.load()
]).then(function () {
    document.documentElement.classList.add('fonts-loaded')
    sessionStorage.setItem('fontsLoaded', true)
}, function () {
    sessionStorage.setItem('fontsLoaded', false)
})
