import $ from 'jquery'
import {altAction} from './dropdown'

export class Search {

    constructor(search) {
        this.nameSpace = search.dataset.js
        this.search = $(search)
        this.form = this.search.find(`[data-js="${this.nameSpace}:form"]`)
        this.input = this.search.find(`[data-js="${this.nameSpace}:input"]`)
    }

    init() {
        this.bindings()
    }

    bindings() {
        this.form.on('submit', this.submit.bind(this))
    }

    submit(event) {

        if (altAction) {

            event.preventDefault()

            const form = event.target
            const url = form.dataset.altAction
            const query = this.input.val()
            const _window = window
            _window.opener = null

            _window.open(url + query)

        }
    }
}

export default function init(selector) {
    const nodes = Array.from(document.querySelectorAll(selector))
    nodes.forEach(node => {
        const module = new Search(node)
        module.init()
    })
}
