import $ from 'jquery'
var shortid = require('shortid')

export class Sidemenu {

	constructor(leftMenu) {
		this.nameSpace = leftMenu.dataset.js
        this.leftMenu = $(leftMenu)             
        this.offsetterContainer = this.leftMenu.find(`[data-js="${this.nameSpace}:container"]`)
        this.offsetter = this.leftMenu.find(`[data-js="${this.nameSpace}:offsetter"]`)
		this.arrow = this.leftMenu.find(`[data-js="${this.nameSpace}:arrow"]`)
		this.item = this.leftMenu.find(`[data-js="${this.nameSpace}:item"]`)
		this.header = $('[data-js="Header"]')
		this.window = $(window)
        this.lastScrollY = window.pageYOffset //window.scrollY
		this.state = {
			class: {
				selected: 'state-selected'
			}
        }
        

	}

	init() {
        window.addEventListener('load', () => {
            this.stickyMenu()
            this.window.on('scroll resize', this.stickyMenu.bind(this))
        })
		this.bindings()
	}

	bindings() {
		
		this.arrow.on('click', this.toggle.bind(this))
	}

	toggle(event) {
		event.preventDefault()

		const target = $(event.target)
		const item = target.closest(this.item)

		item.toggleClass(this.state.class.selected)
		item.siblings(this.item).removeClass(this.state.class.selected)

		// Must call stickyMenu here, to avoid overflow into footer       
        this.stickyMenu()       

	}

    stickyMenu() {       

        let offsetY
        const padding = 15
        const headerStickyHeight = 92        
        const headerFixedHeight = this.header.height() - headerStickyHeight //36
        const topLimit = (window.pageYOffset + headerStickyHeight + padding) - this.leftMenu.offset().top
        const bottomLimit = this.leftMenu.height() - (this.offsetter.height() + padding)
               
        if (window.pageYOffset < headerFixedHeight) {
            
            this.offsetterContainer.removeAttr('style')
            this.offsetter.removeClass('state-fixed') 
            this.offsetter.removeAttr('style')

            return
        }
                 
        if (topLimit > bottomLimit) {         
            offsetY = bottomLimit              
            
            this.offsetter.css({
                'transform': `translateY(${offsetY.toFixed(2)}px)`
            })

            this.offsetterContainer.removeAttr('style')
            this.offsetter.removeClass('state-fixed')

            return
                                        
        } else {
            this.offsetterContainer.css({
                'width': this.leftMenu.width()
            })

            this.offsetter.removeAttr('style')
            
            this.offsetter.addClass('state-fixed')  
         
        }
              
        
	}
}

export function sidemenuInit(selector) {
    const nodes = Array.from(document.querySelectorAll(selector))
    nodes.forEach(node => {
        const module = new Sidemenu(node)
        module.init()
    })
}

export class Mobilemenu {

	constructor(mobilemenu) {
		this.nameSpace = mobilemenu.dataset.js
		this.mobilemenu = $(mobilemenu)
		this.open = $(`[data-js="${this.nameSpace}:open"]`)
		this.burger = $(`[data-js="${this.nameSpace}:burger"]`)
		this.cross = $(`[data-js="${this.nameSpace}:cross"]`)
		this.original = $(`[data-js="${this.nameSpace}:original"]`)
		this.copy = $(`[data-js="${this.nameSpace}:copy"]`)
		this.list = this.original.find(`[data-js="${this.nameSpace}:list"]`)
		this.item = this.original.find(`[data-js="${this.nameSpace}:item"]`)
		this.link = this.original.find(`[data-js="${this.nameSpace}:link"]`)
		this.selected = this.original.find(`[data-state-selected]`)
		this.active = this.original.find(`[data-state-active]`)
		this.arrow = $(`[data-js="${this.nameSpace}:arrow"]`)
		this.body = $('body')
		this.state = {
			closed: true,
			class: {
				mobileActive: 'state-mobile-menu-active',
				selected: 'state-selected'
			}
		}
	}

	init() {
		this.itemShortid()
		this.submenuInit()
		this.bindings()
	}

	bindings() {
		this.open.on('click', this.toggle.bind(this))
		this.copy.on('click', event => {
			if ($(event.target).closest(`[data-js="${this.nameSpace}:back"]`).length !== 0) {
				this.submenuBack()
			}
			if ($(event.target).closest(`[data-js="${this.nameSpace}:list"] [data-js="${this.nameSpace}:link"]`).length !== 0) {
				this.submenuNavigate(event)
			}
		})
	}

	toggle() {
		if (this.state.closed) {
			this.toggleOpen()
			this.state.closed = false
		} else {
			this.toggleClose()
			this.state.closed = true
		}

		this.burger.toggle()
		this.cross.toggle()
	}

	toggleOpen() {
		this.body.addClass(this.state.class.mobileActive)
	}

	toggleClose() {
		this.body.removeClass(this.state.class.mobileActive)
	}

	itemShortid() {
		$.each(this.item, (index, item) => item.setAttribute('data-shortid', shortid.generate()))
	}

	submenuInit() {
		this.copy.html(this.submenuCreateList(this.active))
	}

	submenuBack() {
		const activeItem = this.copy.find(`[data-js="${this.nameSpace}:item"]`).first()
		const activeId = activeItem.data('shortid')
		const originalActiveItem = this.original.find(`[data-shortid="${activeId}"]`)
		const originalParents = originalActiveItem.parents(`[data-js="${this.nameSpace}:item"]`)

		if (originalParents.length) {
			const parentItem = originalParents.first()
			this.copy.html(this.submenuCreateList(parentItem))
		} else {
			this.copy.html(this.submenuCreateList(originalActiveItem, true))
		}
	}

	submenuNavigate(event) {
		const target = $(event.target)
		const item = target.closest(`[data-js="${this.nameSpace}:item"]`)

		if (item.length) {
			const itemId = item.data('shortid')
			const originalItem = this.original.find(`[data-shortid="${itemId}"]`)
			const childList = originalItem.find(`[data-js="${this.nameSpace}:list"]`)

			if (childList.length) {
				event.preventDefault()
				this.copy.html(this.submenuCreateList(originalItem))
			}

		}
	}

	submenuCreateList(originalItem, _root = false) {
		const children = originalItem.find(`[data-js="${this.nameSpace}:list"]`)
		const wrapperList = $('<ul class="nav__list nav__list--mobile-submenu-wrapper" />')
		const backBtn = `
			<li class="nav__item" data-js="${this.nameSpace}:back">
				<a class="nav__link nav__link--mobile-menu-back" data-js="Mobilemenu:back">
					<svg class="svg svg--mobile-menu-back">
						<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icons_arrow-down"></use>
					</svg>
					<span class="nav__link-text">
						Tilbage
					</span>
				</a>
			</li>`

		function cleanClone(clone) {
			clone
				.find(`[data-js="${this.nameSpace}:link"]`).first().addClass('nav__link--mobile-active')
				.find(`[data-js="${this.nameSpace}:arrow"]`).remove()

			if (clone.is(`[data-js="${this.nameSpace}:list"]`)) {
				return clone.find(`[data-js="${this.nameSpace}:list"]`).remove()
			} else {
				return clone.find(`[data-js="${this.nameSpace}:list"] [data-js="${this.nameSpace}:list"]`).remove()
			}
		}

		if (children.length && !_root) {

			const clone = originalItem.clone()
			cleanClone.call(this, clone)

			return wrapperList.html(clone).prepend(backBtn)

		} else {

			const parents = originalItem.parents(`[data-js="${this.nameSpace}:item"]`)

			if (parents.length) {

				const clone = parents.first().clone()
				cleanClone.call(this, clone)

				return wrapperList.html(clone).prepend(backBtn)

			} else {

				const clone = originalItem.parent().clone()
				cleanClone.call(this, clone)

				return clone

			}
		}
	}
}

export function mobilemenuInit(selector) {
    const nodes = Array.from(document.querySelectorAll(selector))
    nodes.forEach(node => {
        const module = new Mobilemenu(node)
        module.init()
    })
}
