import 'objectFitPolyfill'
//find all images not flex and removes data-object-fit
var nonfillimages = document.querySelectorAll('div:not(.col--flex) > .card > .card__block img')

for (var i = 0; i < nonfillimages.length; i++) {
   
    nonfillimages[i].removeAttribute('data-object-fit')
}

//find all images with a special class and removes data-object fit
var nonfillimagesForFlex = document.querySelectorAll('div.not-cut-image > .card > .card__block img')

for (var j = 0; j < nonfillimagesForFlex.length; j++) {

    nonfillimagesForFlex[j].removeAttribute('data-object-fit')
}

window.objectFitPolyfill()
