import $ from 'jquery'
import Swiper from 'swiper'
import getImageSize from 'hesehus-js-image-size'

const $swiperContainers = $('.cms-article .swiper-container').each(function () {
    const $swiper = $(this)
    const layerduration = $swiper.data('layerduration')

    // set width to swiper container to fix swiper not full width
    const mainwidth = $swiper.parent().width()
    $swiper.find('.swiper-wrapper').width(mainwidth)

    const $images = $swiper.find('img')
    let imagesCount = $images.length

    if (imagesCount > 0) {
        $images.each(function () {
            getImageSize(this.src).then(() => {
                imagesCount--
                if (imagesCount <= 0) {
                    setupSwiper()
                }
            })
        })
    }
    else {
        setupSwiper()
    }

    function setupSwiper () {
        const cmsSwiper = Swiper($swiper, {
            pagination: '.swiper-pagination',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 1,
            initialSlide: 0,
            paginationClickable: true,
            spaceBetween: 0,
            loop: false,
            autoplay: layerduration,
            speed: 300
        })

        $swiper.addClass('swiper-ready')
        $swiper.data('swiper', cmsSwiper)
        $swiper.trigger('swiper-ready')
    }
})

$swiperContainers.find('.a-lay').css({
    'background-position': 'top center'
})
