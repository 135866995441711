import $ from 'jquery'

function startVideo (_$figure) {

    const $figure = _$figure,
        $img = $figure.find('.youtube__img'),
        src = $figure.data('url')

    // Append inline to the block
    $figure.addClass('video-inline-added')
    let $template = $(`
        <div class="youtube-inline">
            <iframe src='${src}' frameborder='0' allowfullscreen></iframe>
    </div>`)

    $template.appendTo($img)
    $img.data('template', $template)

}

function stopVideo (_$figure) {
    const $figure = _$figure,
        $img = $figure.find('.youtube')

    $figure.removeClass('video-inline-added')

    let $template = $img.data('template')
    if ($template) {
        $template.remove()
    }
}

function setEvent () {

    const $figure = $(this),
        $img = $figure.find('.youtube__img')

    $img.on('click', function(ev) {
        ev.preventDefault()
        startVideo($figure)
    })

    if ($figure.data('autoplay')) {
        startVideo($figure)
    }
}

function init () {
    $('.cms-article figure.youtube').each(setEvent)
}

init()
