import $ from 'jquery'

export class ToTop {

    constructor(toTop) {
        this.toTop = $(toTop)
        this.scrollLimit = 500
    }

    init() {
        this.bindings()
    }

    bindings() {
        this.toTop.on('click', this.scrollToTop.bind(this))
        window.addEventListener('scroll', this.showHide.bind(this))
    }

    showHide() {
        const scrollTop = $('html').scrollTop()
        scrollTop > this.scrollLimit ? this.toTop.show() : this.toTop.hide()
    }

    scrollToTop(event) {
        event.preventDefault()
        $('html, body').animate({scrollTop: 0}, 100)
    }
}

export default function init(...selectors) {
    selectors.forEach(selector => {
        const nodes = Array.from(document.querySelectorAll(selector))
        nodes.forEach(node => {
            const module = new ToTop(node)
            module.init()
        })
    })
}
