import $ from 'jquery'

export let altAction = false

export class Dropdown {

    constructor(dropdown) {
        this.dropdown = $(dropdown)
        this.text = this.dropdown.find('[data-js="dropdown:text"]')
        this.option = this.dropdown.find('[data-js="dropdown:option"]')
        this.altAction = false
    }

    init() {
        this.bindings()
    }

    bindings() {
        this.option.on('mouseup', this.select.bind(this))
    }

    select(event) {
        const target = $(event.target)
        const text = target.text()

        altAction = event.target.hasAttribute('data-alt-action') ? true : false
        this.text.text(text)
    }
}

export default function init(selector) {
    const nodes = Array.from(document.querySelectorAll(selector))
    nodes.forEach(node => {
        const module = new Dropdown(node)
        module.init()
    })
}
