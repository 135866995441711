module.exports = function imageSize (url, callback) {

  if (window.Promise && typeof callback === 'undefined') {
    return new Promise(function (resolve, reject) {
      go(function (error, size) {
        if (!error) {
          resolve(size);
        }
        else {
          reject(error);
        }
      });
    });
  }
  else {
    go(callback);
  }

  function go (_callback) {

    if (typeof url !== 'string') {
      _callback('url must be a string');
      return;
    }

    var img = document.createElement('img');
    
    img.onload = function onload () {
      _callback(null, {
        width: img.width,
        height: img.height
      });
    }

    img.onerror = function onerror (error) {
      _callback(error);
    }

    img.src = url;
  }
}