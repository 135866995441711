const Modernizr = window.Modernizr

const breakpoint = {

	sizes: {

		// Extra small screen / phone
		xs: 0,
		// Small screen / phone
		sm: 544,
		// Medium screen / tablet
		md: 768,
		// Large screen / desktop
		lg: 992,
		// Extra large screen / wide desktop
		xl: 1200

	},

	min: function(size) {

		if (!this.sizes[size]) { return this.err() }

		return Modernizr.mq('(min-width: ' + this.sizes[size] + 'px)')

	},

	max: function(size) {

		if (!this.sizes[size]) { return this.err() }

		return Modernizr.mq('(max-width: ' + (this.sizes[size] - 1) + 'px)')

	},

	err: function() {

		return console.warn('Breakpoint is invalid')

	},

	getNameFromWidth: function getNameFromWidth (width) {
        if (width <= this.sizes.sm) { return 'xs' }
        else if (width < this.sizes.md) { return 'sm' }
        else if (width < this.sizes.lg) { return 'md' }
        else if (width < this.sizes.xl) { return 'lg' }
        else { return 'xl' }
    }
}

export default breakpoint
