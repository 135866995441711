import $ from 'jquery'
import breakpoint from '../../../modules/breakpoint'

class BackgroundImages {

    constructor (el) {

        this.$el = $(el)

        this.image = this.$el.data('backgroundimage')
        this.image2x = this.$el.data('backgroundimage2x')
        this.imageMobile = this.$el.data('backgroundimagemobile')
        this.imageMobile2x = this.$el.data('backgroundimagemobile2x')
        this.position = this.$el.data('backgroundimageposition')
        this.repeat = this.$el.data('backgroundimagerepeat')

        this.image = this.image.length ? this.image : this.imageMobile
        this.image2x = this.image2x.length ? this.image2x : this.imageMobile2x
        this.imageMobile = this.imageMobile.length ? this.imageMobile : this.image
        this.imageMobile2x = this.imageMobile2x.length ? this.imageMobile2x : this.image2x

        if (this.image.length || this.image2x.length || this.imageMobile.length || this.imageMobile2x) {
            $(window).resize(() => this.refresh())
        }
    }

    refresh () {
        this.viewportWidth = $(window).width()
        this.setBgImage()
    }

    setBgImage () {
        let image = this.image

        if (window.devicePixelRatio > 1) {image = this.image2x}

        if (this.viewportWidth < breakpoint.sizes.md) {
            if (window.devicePixelRatio > 1) {image = this.imageMobile2x}
            else {image = this.imageMobile}
        }


        let css = {
            'background-image': `url('${image}')`,
            'background-position': this.position,
            'background-repeat': this.repeat,
            'background-size': 'cover'
        }
        this.$el.css(css)
    }
}

$('[data-backgroundimage]').each(function () {
    const backgroundImages = new BackgroundImages(this)
    backgroundImages.refresh()
})
