import $ from 'jquery'
import breakpoint from '../../../modules/breakpoint.js'

var $scope = $(document.body)

function run () {

    var screensize = $(window).width()
    var screensizeName = 'desktop'

    if (breakpoint.getNameFromWidth(screensize) === 'sm' || breakpoint.getNameFromWidth(screensize) === 'xs') {
        screensizeName = 'mobile'
    }

    $scope.find('img[data-mobile]').each(function () {
        var $this = $(this),
            srcOld = $this.attr('src'),
            srcNew = $this.data(screensizeName)

        if (srcNew && srcNew !== srcOld) {
            $this.attr('src', srcNew)
        }
    })

}

run()
$(window).resize(run)
