import $ from 'jquery'
import breakpoint from '../../../modules/breakpoint.js'

var $scope = $(document.body)

function handleSwiperSlideVisibility($element, show) {
    if ($element.is('.swiper-slide')) {
        var swiperElement = $element.parents('.swiper-container').first()
        if (swiperElement.is('.swiper-ready')) {

            var swiper = swiperElement.data('swiper')
            if (swiper) {

                var paginationContainer = swiper.paginationContainer
                var slideIndex = swiper.slides.index($element)
                setTimeout(function() {
                    if (show) {
                        $(paginationContainer.children()[slideIndex]).show()
                    } else {
                        $(paginationContainer.children()[slideIndex]).hide()
                    }
                }, 1)
            }
        } else {
            var self = this
            swiperElement.on('swiper-ready', function() {
                handleSwiperSlideVisibility.call(self, $element, show)
            })
        }
    }
}

function run () {

    var screensize = $(window).width()
    var screensizeName = 'desktop'

    if (breakpoint.getNameFromWidth(screensize) === 'sm' || breakpoint.getNameFromWidth(screensize) === 'xs') {
        screensizeName = 'mobile'
    }

    switch (screensizeName) {
        case 'desktop':
            $scope.removeClass('mobile')
            $scope.find('.hide-on-mobile').each(function() {
                var currElement = $(this)
                handleSwiperSlideVisibility(currElement, true)
                currElement.show()
            })
            break
        case 'mobile':
            $scope.addClass('mobile')
            $scope.find('.hide-on-mobile').each(function() {
                var currElement = $(this)
                handleSwiperSlideVisibility(currElement, false)
                currElement.hide()
            })
            break
    }
}

run()
$(window).resize(run)
